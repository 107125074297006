import 'notie/dist/notie.css';
import notie from 'notie/dist/notie.js';
import { v4 as uuidv4 } from 'uuid';

import LocalHub from './LocalHub';
import MqttHub from './MqttHub';

class SyncController {

  static DEFAULT_HOST = process.env.DEFAULT_HUB_HOST ||'geekslides.aprender.cloud';
  static DEFAULT_PORT = process.env.DEFAULT_HUB_PORT || 443;

  uuid;

  slideshowController;

  hub;
  emitting;

  /**
   * If desiredLocation is set to anything different of null, it means we should not
   * publish location events as they are being generated by a move created outside
   * the local slidedeck (and it would produce a deadlock as more than one instances would
   * be compiting for the desired state).
   */
  desiredLocation = null;

  constructor(slideshowController) {
    console.log(`Initializing SyncController.`);

    this.uuid = uuidv4();
  
    this.slideshowController = slideshowController;
    this.emitting = false;

    document.addEventListener('joinRoom',
      (evt) => this.inputUserForNewSession(), true);
    document.addEventListener('toggleEmission', 
      (evt) => this.toggleEmission(), true);      
    document.addEventListener('userOpenedSlides', 
      (evt) => this.toggleEmission(true), true);
    document.addEventListener('slideShown', 
      (evt) => this.#dispatchCurrentSlide(evt.detail.currentSlideIndex, evt.detail.lastPartialShownIndex), true);
    document.addEventListener('partialShown', 
      (evt) => this.#dispatchCurrentSlide(evt.detail.currentSlideIndex, evt.detail.lastPartialShownIndex), true);
    document.addEventListener('slideshowLoaded', 
      (evt) => this.#dispatchSlideshowLoaded(evt.detail.newBaseUrl, evt.detail.currentSlideIndex), true);

    document.addEventListener('startWhiteboardStroke', 
      evt => this.#dispatchWhiteboard(evt.detail.source.id, evt.type, false, evt.detail), true);
    document.addEventListener('endWhiteboardStroke', 
      evt => this.#dispatchWhiteboard(evt.detail.source.id, evt.type, false, evt.detail), true);
    document.addEventListener('whiteboardStroke', 
      evt => this.#dispatchWhiteboard(evt.detail.source.id, evt.type, false, evt.detail), true);
    document.addEventListener('clearWhiteboard', 
      evt => this.#dispatchWhiteboard(evt?.detail?.source.id, evt.type, false), true);

    document.addEventListener('whiteboardShown', 
      evt => this.#dispatchWhiteboard(evt.detail.source.id, evt.type, true), true);
    document.addEventListener('whiteboardHidden', 
      evt => this.#dispatchWhiteboard(evt.detail.source.id, evt.type, true), true);

    // By default, localhub is used to coordinate the different windows being run in the same laptop.
    this.#subscribeToLocalHub();
  }

  async #subscribeToLocalHub() {
    this.hub = new LocalHub();
    await this.hub.connect();
    this.#subscribeToTopics();
  }

  async inputUserForNewSession() {
    let roomDefinition = await this.#input('introduce the room uri, please:', '');
    if (roomDefinition === null) return;

    let host = SyncController.DEFAULT_HOST;
    let port = SyncController.DEFAULT_PORT;
    let roomName = null;
    let roomPassword = null;

    if (roomDefinition.startsWith('//') === false) {
      const parts = roomDefinition.split(' ');
      roomName = parts[0];
      if (parts.length === 2) {
        roomPassword = parts[1];
      }
    } else {
      /*
      This regex will catch this kind of patterns:

      //mqtt.aprender.cloud:443/xyz abc
      //mqtt.aprender.cloud:443/xyz abc
      //mqtt.aprender.cloud:443/xyz
      //mqtt.aprender.cloud/xyz abc
      //mqtt.aprender.cloud/xyz abc
      //mqtt.aprender.cloud/xyz

      Port and password are optional. See https://regex101.com/r/CWrZhZ/1 
      to play with it interactively.
      */
      const regex = /^\/\/(.*?):?(\d{1,5})?\/((.*) (.*)|(.*))$/gm;
      const result = regex.exec(roomDefinition);
      host = result[1];
      if (result[2]) {
        port = parseInt(result[2]);
      }
      if (result[6]) {
        roomName = result[6];
      } else {
        roomName = result[4];
        roomPassword = result[5];
      }
    }
    console.info(`Joining room ${roomName} with password ${roomPassword} on ${host}:${port}.`);
    const username = roomPassword ? 'producer' : 'consumer';
    this.connectToMqttHub(host, port, roomName, username, roomPassword);
  }

  #input(text, value) {        
    return new Promise((resolve, reject) => {
      const options = { 
        text,
        value,
        submitText : 'Accept',
        position : 'bottom',
        submitCallback : v => resolve(v),
        cancelCallback : v => resolve(null)
      };
  
      notie.input(options);
    });
  }


  async connectToMqttHub(host, port, roomName, username, password) {
    if (this.hub) {
      await this.disconnectFromHub();
    }
    this.hub = new MqttHub(host, port, roomName, username, password);
    await this.hub.connect();
    this.#subscribeToTopics();
  }

  #subscribeToTopics() {
    this.hub.subscribeListener('slides', (p) => {
      this.#processSlideMessage(JSON.parse(p))
    });
    this.hub.subscribeListener('slideShowLoaded', (p) => this.#processSlideMessage(JSON.parse(p)));
    this.hub.subscribeListener('slides/whiteboard', (p) => this.#processWhiteboard(JSON.parse(p)));
  }

  async disconnectFromHub() {
    try {
      return await this.hub.disconnect();
    } catch (err) {
      console.warn(`Error disconnecting from previous hub (${err}).`);
    }
  }

  toggleEmission(optionalNewValue) {
    this.emitting = (optionalNewValue === undefined) ? !this.emitting : optionalNewValue;
    if (this.emitting === true) {
      const message = 'Emitting current slidedeck position';
      notie.alert({type : 'info', text : message, position : 'bottom'});
      this.#dispatchTakeControl(); 
      this.#dispatchCurrentSlide(this.slideshowController.slideshow.getCurrentSlideIndex(),
                                 this.slideshowController.slideshow.getCurrentPartialIndex()); 
    } else {
      const message = 'Deactivating current slidedeck position emission.';
      notie.alert({type : 'warning', text : message, position : 'bottom'});
    }
    return this.emitting;
  }


  #dispatchTakeControl() {
    const payload = {
      action: 'control', 
      syncControllerUuid : this.uuid
    };
    this.hub.emitMessage('slides', payload);
  }

  #dispatchCurrentSlide(currentSlideIndex, lastPartialShownIndex) {
    // don't try to force external state if we are trying to reach one sent by other instance.    
    if (this.desiredLocation !== null) {
      return;
    }
    
    if (this.emitting === true) {
      const payload = {
        action: 'location', 
        baseUrl: this.slideshowController.baseUrl,
        currentSlideIndex, 
        lastPartialShownIndex,
        syncControllerUuid : this.uuid
      };
      this.hub.emitMessage('slides', payload);
    }
  }

  #dispatchSlideshowLoaded(newBaseUrl, currentSlideIndex) {
    if (this.emitting === false) return;

    const payload = {
      action: 'slideShowLoaded', 
      newBaseUrl, 
      currentSlideIndex,
      syncControllerUuid : this.uuid
    };
    this.hub.emitMessage('slides', payload, 0, true);
  }

  #processSlideMessage(message) {
    // don't process messages generated by this browser
    if (message.syncControllerUuid === this.uuid) return;

    if (message.action === 'control') {
      // Inform there is another instance controlling the slideshow
      if (this.emitting === true) {
        const message = 'Another instance is also controlling the slideshow.';
        notie.alert({type : 'warning', text : message, position : 'bottom'});
      }
    } else if (message.action === 'location') {
      if (this.emitting === true) {
        //this.toggleEmission(false);
      }
      if (this.slideshowController.baseUrl !== message.baseUrl) {
        this.slideshowController.changeSlideshowContent(
          message.baseUrl,
          message.currentSlideIndex
        );
      } else {
        this.desiredLocation = {
          desiredIndex : message.currentSlideIndex, 
          desiredPartial : message.lastPartialShownIndex
        };
        this.slideshowController.slideshow.gotoSlideIndex(
          this.desiredLocation.desiredIndex, this.desiredLocation.desiredPartial);
        this.desiredLocation = null;
      }
    } else if (message.action === 'slideShowLoaded') {
      if (this.emitting === false) {
        this.slideshowController.changeSlideshowContent(message.newBaseUrl, message.currentSlideIndex);
      }
    }
  }

  #dispatchWhiteboard(id, action, retain, detail){
    id = id ? id : null;
    detail = detail ? Object.assign(detail) : {};
    delete detail.source;
    if (this.emitting === false) return;
    const payload = {
      syncControllerUuid : this.uuid,
      action, 
      id, 
      detail
    };
    this.hub.emitMessage('slides/whiteboard', payload, 0, retain);
  }

  #processWhiteboard(message) {
    // don't process messages generated by this browser
    if (message.syncControllerUuid === this.uuid) return;

    // process it by generating an event from the corresponding source
    delete message.syncControllerUuid
    const elem = message.id ? 
                 document.getElementById(message.id) : this.slideshowController.slideshow.getCurrentSlideElem().querySelector('.whiteboard');
    const detail = Object.assign(message.detail ? message.detail : {});
    detail.action = message.action;
    let evt = new CustomEvent('remoteWhiteboard', { detail });
    elem.dispatchEvent(evt);
  }
}

export default SyncController;